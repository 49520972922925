var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "EditLayout",
    {
      attrs: {
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
        "supports-editor": false,
      },
      on: {
        submit: function ($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        },
      },
    },
    [
      _c("PermissionsCheckList", {
        attrs: {
          slot: "bottom",
          "active-permissions": _vm.currentRolePermissions,
        },
        on: { updatePermissions: _vm.updatePermissions },
        slot: "bottom",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }